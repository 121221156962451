import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const NotFound = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const NotFoundPage = () => (
  <Layout className="notfound" flexDown="flex-down">
    <NotFound>
      <SEO title="404: Not found" />
      <p>
        Something has gone wrong, you got to a URL that doesn't exist. Email us
        with details?
      </p>
    </NotFound>
  </Layout>
)

export default NotFoundPage
